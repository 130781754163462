import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";
import { getUserByToken } from "./authCrud";
import {setEmyRole} from "../../../../_metronic/i18n";
import {toAbsoluteUrl} from "../../../../_metronic/_helpers";
import {roles} from "../../../../index";

export const actionTypes = {
  Login: "[Login] Action",
  Logout: "[Logout] Action",
  Register: "[Register] Action",
  UserRequested: "[Request User] Action",
  UserLoaded: "[Load User] Auth API",
  VerifyEmail: "[Verify Email] Action"
};

// console.log(roles)
const initialAuthState = {
  user: undefined,
  access_token: undefined,
  // role: "ROLE_VISITOR"
  // role: roles.visitor
};

export const reducer = persistReducer(
  { storage, key: "v706-demo3-auth", whitelist: ["user", "access_token"/*,"role"*/] },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Login: {
        const { access_token } = action.payload;
        return { access_token, user: undefined };
      }

      case actionTypes.Register: {
        const { access_token } = action.payload;
        return { access_token, user: undefined };
      }

      case actionTypes.Logout: {
        // TODO: Change this code. Actions in reducer aren't allowed.
        return initialAuthState;
      }

      case actionTypes.UserLoaded: {
        const { user } = action.payload;
        return { ...state, user };
      }

      case actionTypes.VerifyEmail: {

        // const { role } = action.payload;
        // console.log('switch', role)
        return { ...state };
      }

      default:
        return state;
    }
  }
);

export const authActions = {
  login: access_token => ({ type: actionTypes.Login, payload: { access_token } }),
  emailVerified: () => ({ type: actionTypes.VerifyEmail }),
  register: access_token => ({  type: actionTypes.Register, payload: { access_token } }),
  logout: () => ({ type: actionTypes.Logout }),
  requestUser: user => ({ type: actionTypes.UserRequested, payload: { user } }),
  fulfillUser: user => ({ type: actionTypes.UserLoaded, payload: { user } })
};

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga() {
    yield put(authActions.requestUser());
  });

  yield takeLatest(actionTypes.Register, function* registerSaga() {
    yield put(authActions.requestUser());
  });
  yield takeLatest(actionTypes.VerifyEmail, function* verifiedEmail() {
    const { data: user } = yield getUserByToken();
    yield put(authActions.fulfillUser(user));
    setEmyRole(user.roles[0], false);
    window.location.replace(toAbsoluteUrl())
  });

  yield takeLatest(actionTypes.UserRequested, function* userRequested() {
    const { data: user } = yield getUserByToken();
    const role = user.roles.length > 0 ? user.roles[0] : roles.visitor
    yield put(authActions.fulfillUser(user));
    setEmyRole(role, true);
  });

}
