import { createSlice } from "@reduxjs/toolkit";
import { AEMetadata } from "../../../../_emywork/AEPagination";
import { getConfig } from "../../../../_metronic/i18n";
import { localField } from "../../../../_emywork/helpers/UIHelper";

export const actionTypes = {
  List: "list",
  Delete: "deleteDialog",
  Edit: "editForm",

  ProjectFilter: "ProjectFilter",
  EmyServiceFilter: "EmyServiceFilter",
  EmyProductFilter: "EmyProductFilter",
  UserFilter: "UserFilter",
  UserEditList: "UserEditList",
};

export const defaultEntity =
  process.env.NODE_ENV === "development"
    ? {
        id: undefined,
        name_ar: "JAVA",
        name_en: "JAVA",
        name_fr: "JAVA",
        name_es: "JAVA",
        country_id: 1,
      }
    : {
        id: undefined,
        name_ar: "",
        name_en: "",
        name_fr: "",
        country_id: null,
      };
const { selectedLang } = getConfig();

export const slice = createSlice({
  name: "locations",
  initialState: {
    [actionTypes.List]: {
      entities: [],
      error: null,
      isLoading: false,
      meta: new AEMetadata({}, { field: `name_${selectedLang}` }, {}, [
        "*",
        "country",
      ]),
    },
    [actionTypes.Delete]: {
      entity: defaultEntity,
      error: null,
      isLoading: false,
    },

    [actionTypes.Edit]: {
      entity: defaultEntity,
      validationErrors: [],
      isLoading: false,
      error: null,
    },

    [actionTypes.ProjectFilter]: {
      entities: [],
      error: null,
      isLoading: false,
      meta: new AEMetadata(
        { perPage: 1000 },
        { field: `name_${selectedLang}` },
        {},
        ["id", localField("name")]
      ),
    },

    [actionTypes.EmyServiceFilter]: {
      entities: [],
      error: null,
      isLoading: false,
      meta: new AEMetadata(
        { perPage: 1000 },
        { field: `name_${selectedLang}` },
        {},
        ["id", localField("name")]
      ),
    },

    [actionTypes.EmyProductFilter]: {
      entities: [],
      error: null,
      isLoading: false,
      meta: new AEMetadata(
        { perPage: 1000 },
        { field: `name_${selectedLang}` },
        {},
        ["id", localField("name")]
      ),
    },

    [actionTypes.UserFilter]: {
      entities: [],
      error: null,
      isLoading: false,
      meta: new AEMetadata(
        { perPage: 1000 },
        { field: `name_${selectedLang}` },
        {},
        ["id", localField("name")]
      ),
    },
    [actionTypes.UserEditList]: {
      entities: [],
      error: null,
      isLoading: false,
      meta: new AEMetadata(
        { perPage: 1000 },
        { field: `name_${selectedLang}` },
        {},
        ["id", localField("name")]
      ),
    },
  },
  reducers: {
    startCall: (state, action) => {
      const { type } = action.payload;
      if (!type in actionTypes) return;

      state[type].error = null;
      state[type].isLoading = true;

      if ([actionTypes.Edit].includes(type)) {
        state[type].validationErrors = [];
      }
    },
    endCall: (state, action) => {
      const { error, type, entity } = action.payload;

      if (!type in actionTypes) return;

      state[type].error = error;
      state[type].isLoading = false;

      if ([actionTypes.Edit].includes(type)) {
        state[type].validationErrors = [];
      }
    },
    catchError: (state, action) => {
      const { data, type } = action.payload;
      if (!type in actionTypes) return;

      state[type].error = true;
      state[type].isLoading = false;

      if ([actionTypes.Edit].includes(type)) {
        state[type].validationErrors = data;
      }
    },
    fetched: (state, action) => {
      const { type } = action.payload;
      if (!type in actionTypes) return;
      state[type].error = null;
      state[type].isLoading = false;

      if ([actionTypes.Edit, actionTypes.Delete].includes(type)) {
        const { entity } = action.payload;
        state[type].entity = entity;
        if ([actionTypes.Edit].includes(type)) {
          state[type].validationErrors = [];
        }
      }
      if (
        [
          actionTypes.List,
          actionTypes.ProjectFilter,
          actionTypes.EmyServiceFilter,
          actionTypes.EmyProductFilter,
          actionTypes.UserFilter,
          actionTypes.UserEditList,
        ].includes(type)
      ) {
        const { entities, meta } = action.payload;
        if (meta) {
          const {
            pagination: { total, pages },
          } = meta;
          state[type].meta.pagination.total = total;
          state[type].meta.pagination.pages = pages;
        }
        state[type].entities = entities;
      }
    },
  },
});
