import axios from "axios";
import {AEMetadata} from "../../../../_emywork/AEPagination";
export const API_URI = process.env.REACT_APP_API_URL+"/auth";

export const login = (email, password) => axios.post(`${API_URI}/login`, { email, password });
export const register = entity => axios.post(`${API_URI}/register`, entity);
export const requestPassword = email => axios.post(`${API_URI}/request_password`, { email });
export const updateForgottenPassword = (data) => axios.post(`${API_URI}/update_forgotten_password`, data);
export const requestEmailVerificationLink = () => axios.post(`${API_URI}/request_email_verification`);
export const verifyEmail = (token, object) => axios.post(`${API_URI}/verify_email/${token}`, object);
export const getUserByToken = () => axios.get(`${API_URI}/me`,{params : { meta : new AEMetadata({},{},{},['*','location'])}});
export const updatePassword = (object) => axios.post(`${API_URI}/update_password`, object);

