import { createSlice } from "@reduxjs/toolkit";
import { AEMetadata } from "../../../../_emywork/AEPagination";
import { getConfig } from "../../../../_metronic/i18n";
import { defaultEntity } from "../helpers/ModuleHelpers";

export const actionTypes = {
  List: "list",
  Delete: "deleteDialog",
  Edit: "editForm",
  Details: "details",
  TeamsFormList: "TeamsFormList",
  GroupsFormList: "GroupsFormList",
  Chat: "Chat",
  EditPassword: "EditPassword",
};

const { selectedLang } = getConfig();

export const slice = createSlice({
  name: "users",
  initialState: {
    [actionTypes.List]: {
      entities: [],
      error: null,
      isLoading: false,
      meta: new AEMetadata(
        {
          perPage: 12,
        },
        { field: `name_${selectedLang}` },
        {},
        [
          "id",
          "username",
          "email",
          "badge",
          "about",
          "legal_form_id",
          "is_saved",
          "rating",
          "rates",
          "file_name",
          "legal_form",
          "feedbacks",
          "skills",
          "is_active",
        ]
      ),
    },
    [actionTypes.TeamsFormList]: {
      entities: [],
      error: null,
      isLoading: false,
      meta: new AEMetadata(
        {
          perPage: 1000,
        },
        { field: `name_${selectedLang}` },
        {},
        [
          "id",
          "username",
          "email",
          "badge",
          "about",
          "legal_form_id",
          "is_saved",
          "rating",
          "rates",
          "file_name",
          "legal_form",
          "feedbacks",
          "skills",
        ]
      ),
    },
    [actionTypes.GroupsFormList]: {
      entities: [],
      error: null,
      isLoading: false,
      meta: new AEMetadata(
        {
          perPage: 1000,
        },
        {},
        {},
        [
          "id",
          "username",
          "email",
          "badge",
          "about",
          "legal_form_id",
          "is_saved",
          "rating",
          "rates",
          "file_name",
          "legal_form",
          "feedbacks",
          "skills",
        ]
      ),
    },
    [actionTypes.Delete]: {
      entity: defaultEntity,
      error: null,
      isLoading: false,
    },

    [actionTypes.Edit]: {
      entity: defaultEntity,
      validationErrors: [],
      isLoading: false,
      error: null,
    },
    [actionTypes.Details]: {
      entity: defaultEntity,
      isLoading: false,
      error: null,
    },

    [actionTypes.Chat]: {
      entity: defaultEntity,
      isLoading: false,
      error: null,
    },
    [actionTypes.EditPassword]: {
      entity: {
        current: undefined,
        new: undefined,
        _confirm: undefined,
      },
      validationErrors: [],
      isLoading: false,
      error: null,
    },
  },
  reducers: {
    startCall: (state, action) => {
      const { type } = action.payload;
      if (!type in actionTypes) return;

      // console.log(state[type], state, type)
      state[type].error = null;
      state[type].isLoading = true;

      if ([actionTypes.Edit, actionTypes.EditPassword].includes(type)) {
        state[type].validationErrors = [];
      }
    },
    endCall: (state, action) => {
      const { error, type, entity } = action.payload;

      if (!type in actionTypes) return;

      state[type].error = error;
      state[type].isLoading = false;

      if ([actionTypes.Edit, actionTypes.EditPassword].includes(type)) {
        state[type].validationErrors = [];
      }

      if ([actionTypes.Details, actionTypes.Chat].includes(type)) {
        const { id, is_saved } = entity;

        state[type].entity.is_saved = is_saved;
      }

      if (
        [
          actionTypes.List,
          actionTypes.TeamsFormList,
          actionTypes.GroupsFormList,
        ].includes(type)
      ) {
        const { id, is_saved } = entity;

        state[type].entitites = state[type].entities.map((e) => {
          if (e.id === id) {
            e.is_saved = is_saved;
          }
          return e;
        });
      }
    },
    catchError: (state, action) => {
      const { data, type } = action.payload;
      if (!type in actionTypes) return;

      state[type].error = true;
      state[type].isLoading = false;

      if ([actionTypes.Edit, actionTypes.EditPassword].includes(type)) {
        state[type].validationErrors = data;
      }
    },
    fetched: (state, action) => {
      const { type } = action.payload;
      if (!type in actionTypes) return;
      state[type].error = null;
      state[type].isLoading = false;

      if (
        [
          actionTypes.Edit,
          actionTypes.Delete,
          actionTypes.Details,
          actionTypes.Chat,
          actionTypes.EditPassword,
        ].includes(type)
      ) {
        const { entity } = action.payload;
        state[type].entity = entity;
        if ([actionTypes.Edit, actionTypes.EditPassword].includes(type)) {
          state[type].validationErrors = [];
        }
      }
      if (
        [
          actionTypes.List,
          actionTypes.TeamsFormList,
          actionTypes.GroupsFormList,
        ].includes(type)
      ) {
        const { entities, meta } = action.payload;
        if (meta) {
          const {
            pagination: { total, pages },
          } = meta;
          state[type].meta.pagination.total = total;
          state[type].meta.pagination.pages = pages;
        }
        state[type].entities = entities;
      }
    },
    //
    addComment: (state, action) => {
      const { type, comment } = action.payload;
      if (!type in actionTypes) return;

      if ([actionTypes.Details, actionTypes.Chat].includes(type)) {
        state[type].entity.comments.push(comment);
      }
    },
  },
});
