import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import * as Dashboard from "../app/pages/Dashboard/_redux/slice";

import { slice as skillsSlice } from "../app/modules/Skills/_redux/slice";
import { slice as locationsSlice } from "../app/modules/Locations/_redux/slice";
import { slice as activityAreasSlice } from "../app/modules/ActivityAreas/_redux/slice";
import { slice as countriesSlice } from "../app/modules/Countries/_redux/slice";
import { slice as categoriesSlice } from "../app/modules/Categories/_redux/slice";
import { slice as subcategoriesSlice } from "../app/modules/Subcategories/_redux/slice";
import { slice as emyProjectsSlice } from "../app/modules/EmyProjects/_redux/slice";
import { slice as projectTypesSlice } from "../app/modules/ProjectTypes/_redux/slice";
import { slice as projectCommentsSlice } from "../app/modules/ProjectComments/_redux/slice";
import { slice as projectProposalsSlice } from "../app/modules/ProjectProposals/_redux/slice";
import { slice as legalFormsSlice } from "../app/modules/LegalForms/_redux/slice";
import { slice as teamsSlice } from "../app/modules/Teams/_redux/slice";
import { slice as groupsSlice } from "../app/modules/Groups/_redux/slice";
import { slice as calendarSlice } from "../app/modules/Calendar/_redux/slice";
import { slice as contactsSlice } from "../app/modules/Contacts/_redux/slice";
import { slice as functionsSlice } from "../app/modules/Functions/_redux/slice";
import { slice as PaymentTermsSlice } from "../app/modules/PaymentTerms/_redux/slice";
import { slice as usersSlice } from "../app/modules/Users/_redux/slice";
import { slice as userMessagesSlice } from "../app/modules/Chat/_redux/messages/slice";
import { slice as userAwardsSlice } from "../app/modules/Users/_redux/Awards/slice";
import { slice as userExperiencesSlice } from "../app/modules/Users/_redux/Experiences/slice";
import { slice as userEducationsSlice } from "../app/modules/Users/_redux/Educations/slice";
import { slice as chatSlice } from "../app/modules/Chat/_redux/slice";
import { slice as ProposalSlice } from "../app/modules/Proposals/_redux/slice";
// import { slice as EmyCoinSlice } from "../app/modules/EmyCoins/_redux/slice";
import { slice as NotificationSlice } from "../app/modules/Auth/_redux/Notifications/slice";

import { slice as EmyServicesSlice } from "../app/modules/EmyServices/_redux/slice";
import { slice as EmyServiceReviewsSlice } from "../app/modules/EmyServices/_redux/Reviews/slice";

import { slice as EmyProductsSlice } from "../app/modules/EmyProducts/_redux/slice";
import { slice as EmyProductReviewsSlice } from "../app/modules/EmyProducts/_redux/Reviews/slice";

//wallets
import { slice as Deposits } from "../app/modules/EmyWallet/_redux/deposits/slice";
import { slice as Transactions } from "../app/modules/EmyWallet/_redux/transactions/slice";
import { slice as Wallets } from "../app/modules/EmyWallet/_redux/slice";

export const rootReducer = combineReducers({
  statistics: Dashboard.slice.reducer,
  auth: auth.reducer,
  emy_projects: emyProjectsSlice.reducer,
  locations: locationsSlice.reducer,
  legal_forms: legalFormsSlice.reducer,
  activity_areas: activityAreasSlice.reducer,
  chat_rooms: chatSlice.reducer,
  teams: teamsSlice.reducer,
  groups: groupsSlice.reducer,
  contacts: contactsSlice.reducer,
  calendar_events: calendarSlice.reducer,
  functions: functionsSlice.reducer,
  users: usersSlice.reducer,
  user_awards: userAwardsSlice.reducer,
  user_experiences: userExperiencesSlice.reducer,
  user_educations: userEducationsSlice.reducer,
  user_messages: userMessagesSlice.reducer,
  skills: skillsSlice.reducer,
  countries: countriesSlice.reducer,
  categories: categoriesSlice.reducer,
  subcategories: subcategoriesSlice.reducer,
  project_types: projectTypesSlice.reducer,
  project_comments: projectCommentsSlice.reducer,
  project_proposals: projectProposalsSlice.reducer,
  payment_terms: PaymentTermsSlice.reducer,
  proposals: ProposalSlice.reducer,
  // emy_coins: EmyCoinSlice.reducer,
  notifications: NotificationSlice.reducer,

  emy_services: EmyServicesSlice.reducer,
  emy_service_reviews: EmyServiceReviewsSlice.reducer,

  emy_products: EmyProductsSlice.reducer,
  emy_product_reviews: EmyProductReviewsSlice.reducer,
  deposits: Deposits.reducer,
  transactions: Transactions.reducer,
  wallets: Wallets.reducer,
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
