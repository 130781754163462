import { createSlice } from "@reduxjs/toolkit";
import { AEMetadata } from "../../../../_emywork/AEPagination";
import { localField } from "../../../../_emywork/helpers/UIHelper";
import { getConfig } from "../../../../_metronic/i18n";

export const actionTypes = {
  List: "list",
  Delete: "deleteDialog",
  Edit: "editForm",
  Details: "details",
};

const { selectedLang } = getConfig();

export const slice = createSlice({
  name: "wallets",
  initialState: {
    [actionTypes.List]: {
      entities: [],
      error: null,
      isLoading: false,
      meta: new AEMetadata(
        {
          perPage: 12,
        },
        {},
        {},
        ["*"]
      ),
    },

    [actionTypes.Delete]: {
      error: null,
      isLoading: false,
    },

    [actionTypes.Edit]: {
      validationErrors: [],
      isLoading: false,
      error: null,
    },
    [actionTypes.Details]: {
      isLoading: false,
      error: null,
    },

    [actionTypes.UserFilter]: {
      entities: [],
      error: null,
      isLoading: false,
      meta: new AEMetadata(
        { perPage: 1000 },
        { field: `name_${selectedLang}` },
        {},
        ["id", localField("name")]
      ),
    },
  },
  reducers: {
    startCall: (state, action) => {
      const { type } = action.payload;
      if (!type in actionTypes) return;

      // console.log(state[type], state, type)
      state[type].error = null;
      state[type].isLoading = true;

      if ([actionTypes.Edit].includes(type)) {
        state[type].validationErrors = [];
      }
    },
    endCall: (state, action) => {
      const { error, type, entity } = action.payload;

      if (!type in actionTypes) return;

      state[type].error = error;
      state[type].isLoading = false;

      if ([actionTypes.Edit].includes(type)) {
        state[type].validationErrors = [];
      }

      if ([actionTypes.List, actionTypes.UserList].includes(type)) {
        const { id, is_saved } = entity;

        state[type].entitites = state[type].entities.map((e) => {
          if (e.id === id) {
            e.is_saved = is_saved;
          }
          return e;
        });
      }
    },
    catchError: (state, action) => {
      const { data, type } = action.payload;
      if (!type in actionTypes) return;

      state[type].error = true;
      state[type].isLoading = false;

      if ([actionTypes.Edit].includes(type)) {
        state[type].validationErrors = data;
      }
    },
    fetched: (state, action) => {
      const { type } = action.payload;
      if (!type in actionTypes) return;
      state[type].error = null;
      state[type].isLoading = false;

      if (
        [actionTypes.Edit, actionTypes.Delete, actionTypes.Details].includes(
          type
        )
      ) {
        const { entity } = action.payload;
        state[type].entity = entity;
        if ([actionTypes.Edit].includes(type)) {
          state[type].validationErrors = [];
        }
      }
      if ([actionTypes.List, actionTypes.UserList].includes(type)) {
        const { entities, meta } = action.payload;
        if (meta) {
          const {
            pagination: { total, pages },
          } = meta;
          state[type].meta.pagination.total = total;
          state[type].meta.pagination.pages = pages;
        }
        state[type].entities = entities;
      }
    },
  },
});
